(function()
{
 	var app = angular.module("app");

 	app.controller('HeaderController', ['$scope', '$http', '$rootScope', '$mdSidenav', 'functions', function($scope, $http, $rootScope, $mdSidenav, functions)
 	{
 		$scope.qSelected 	= "";
 		$scope.qText 		= "";

 		$scope.toggleLeftMenu = function()
		{
			// jQuery.sidr("toggle", "sidr");
			// $(".md-sidenav-left").show();
			$mdSidenav("left").toggle();
		};

		$scope.closeLeftMenu = function()
		{
			// jQuery.sidr("toggle", "sidr");
			$mdSidenav("left").close();
			// setTimeout(function() { $(".md-sidenav-left").hide(); }, 1000);
		};

		$scope.setAction = function(q)
		{
			document.getElementById('search-form').action='/search/' + encodeURIComponent(q.replace(/[,.!@#$%^&*()<>\[\]{}?=~`|\\\/;:]/g, ""))
		};

		$scope.getMatches = function(q)
		{
			if(q == "")
			{
				return [];
			}

			// We must use jQuery's AJAX call because AngularJS does not allow us to do synchronous calls.
			// We must use a synchronous call so that we can return the result.
			var results = $.ajax(
			{
		        type: "GET",
		        url: '/autocomplete/search/' + encodeURIComponent(q),
		        async: false
		    }).responseText;

			return JSON.parse(results);
		};
	}]);
})();
